import React from "react";
import ProjectPreviewGrid from "./project-preview-grid";
import OurWork from "./our-work";
import Services from "./services";
import Intro from "./intro";
import ClientsList from "./clients-list";
import PageSections from "./page-sections";
import styles from "./home-page.module.css";
import FeaturedLinks from "./featured-links";

const HomePage = (props) => {
  const {
    introTitleCopy,
    aboutButtonCopy,
    _rawAboutCopy,
    aboutCopy,
    clientsTitleCopy,
    clientsSubtitleCopy,
    clientsAfterCopy,
    desktopLogoGrid,
    mobileLogoGrid,
    selectedProjects,
    caseStudiesTitleCopy,
    caseStudiesReadMoreCopy,
    splashPageEnabled,
    introBackgroundVideo,
    introLottieMobile,
    introLottieDesktop,
    _rawContentOne,
    contentOne,
    _rawContentTwo,
    contentTwo,
    featuredLinks,
    featuredLinksLottie,
    featuredLinksLottieText,
  } = props;
  const aboutCopyBlocks = _rawAboutCopy ? _rawAboutCopy : aboutCopy;
  const sectionContentOne = _rawContentOne ? _rawContentOne : contentOne;
  const sectionContentTwo = _rawContentTwo ? _rawContentTwo : contentTwo;

  return (
    <main className={styles.root}>
      <Services
        splashPageEnabled={splashPageEnabled}
        introLottieMobile={introLottieMobile}
        introLottieDesktop={introLottieDesktop}
      />
      <Intro
        title={introTitleCopy}
        buttonCopy={aboutButtonCopy}
        body={aboutCopyBlocks}
        introBackgroundVideo={introBackgroundVideo}
      />
      {featuredLinks && !!featuredLinks.length && (
        <FeaturedLinks
          links={featuredLinks}
          lottieFile={featuredLinksLottie}
          text={featuredLinksLottieText}
        />
      )}
      <ClientsList
        title={clientsTitleCopy}
        subtitle={clientsSubtitleCopy}
        afterCopy={clientsAfterCopy}
        desktopLogos={desktopLogoGrid}
        mobileLogos={mobileLogoGrid}
      />
      {sectionContentOne && !!sectionContentOne.length && (
        <PageSections content={sectionContentOne} hasPaddingTop className={styles.pageSections} />
      )}
      {selectedProjects && (
        <OurWork title={caseStudiesTitleCopy}>
          {selectedProjects && (
            <ProjectPreviewGrid
              buttonCopy={caseStudiesReadMoreCopy}
              nodes={selectedProjects}
              browseMoreHref="/work/"
            />
          )}
        </OurWork>
      )}
      {sectionContentTwo && !!sectionContentTwo.length && (
        <PageSections content={sectionContentTwo} hasPaddingTop className={styles.pageSections} />
      )}
    </main>
  );
};

export default HomePage;
