import React from "react";
import styles from "./clients-list.module.css";
import { responsiveTitle2, responsiveMicro } from "./typography.module.css";
import { buildImageObj, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import plusIcon from "../images/Capacity_PlusAccent.svg";

const ClientsList = (props) => {
  const { title, subtitle, afterCopy, desktopLogos, mobileLogos } = props;
  return (
    <div className={styles.root}>
      <div className={styles.contentWrapper}>
        {title && <h2 className={cn(responsiveTitle2, styles.title)}>{title}</h2>}

        {subtitle && <div className={cn(styles.subtitle)}>{subtitle}</div>}

        <div className={styles.logoGrid}>
          <div className={cn(styles.plusTop, styles.plus)}>
            <img src={plusIcon} alt="plus accent" />
            <img src={plusIcon} alt="plus accent" />
          </div>
          {desktopLogos && desktopLogos.asset && (
            <img
              className={styles.desktopLogoGrid}
              src={imageUrlFor(buildImageObj(desktopLogos)).auto("format").quality(100).url()}
              alt={desktopLogos.alt}
            />
          )}
          {mobileLogos && mobileLogos.asset && (
            <img
              className={styles.mobileLogoGrid}
              src={imageUrlFor(buildImageObj(mobileLogos)).auto("format").url()}
              alt={mobileLogos.alt}
            />
          )}
          <div className={cn(styles.plusBottom, styles.plus)}>
            <img src={plusIcon} alt="plus accent" />
            <img src={plusIcon} alt="plus accent" />
          </div>
        </div>
        {afterCopy && <div className={cn(styles.afterCopy)}>{afterCopy}</div>}
      </div>
    </div>
  );
};

export default ClientsList;
