import Lottie from "react-lottie-player";
import React, { useState, useEffect } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { pageTransition } from "../lib/transition-config";

import styles from "./featured-links.module.css";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import MenuSelector from "../images/MenuSelector.svg";

const FeaturedLinks = ({ links, lottieFile, text }) => {
  const [animationData, setAnimationData] = useState(null);

  const defaultOptions = {
    loop: true,
  };

  useEffect(() => {
    if (lottieFile) {
      const fetchData = async () => {
        const response = await fetch(lottieFile.asset.url);
        let responseJson = await response.json();

        setAnimationData(responseJson);
      };
      fetchData();
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {links.map(({ url, label, newTab }, index) =>
          newTab ? (
            <Link
              key={index}
              href={url}
              target={newTab ? "_blank" : ""}
              className={cn(styles.link, "test")}
            >
              <img src={MenuSelector} alt="selector" className={styles.selector} />
              <div className={styles.linkInner}>{label}</div>
            </Link>
          ) : (
            <AniLink key={index} className={cn(styles.link)} {...pageTransition} to={url}>
              <img src={MenuSelector} alt="selector" className={styles.selector} />
              <div className={styles.linkInner}>{label}</div>
            </AniLink>
          )
        )}
      </div>
      <div className={styles.lottieWrapper}>
        <div className={styles.lottie}>
          {lottieFile && animationData && (
            <Lottie {...defaultOptions} play={true} animationData={animationData} />
          )}
        </div>
        {text && <div className={styles.lottieText}>{text}</div>}
      </div>
    </div>
  );
};

export default FeaturedLinks;
