import React, { useEffect, useRef } from "react";
import Rellax from "rellax";
import PortableText from "./portableText";
import styles from "./intro.module.css";
import { cn } from "../lib/helpers";
import useToggleExpand from "../lib/useToggleExpand";
import { responsiveMicro, responsiveTitle2, bodyTextBlock } from "./typography.module.css";
import shieldVideo from "../videos/Capacity_Header_BG_new.mp4";
import { useMediaQuery } from "react-responsive";
import { mediaMaxSmall } from "../lib/mediaQueries";
import shieldIcon from "../images/shield-blank-red.svg";

const Intro = (props) => {
  const { title, body, buttonCopy, introBackgroundVideo } = props;
  const isMobile = useMediaQuery(mediaMaxSmall);

  const introTextRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const rellaxOptions = {
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    };
    const introTextRellax = new Rellax(introTextRef.current, {
      ...rellaxOptions,
      speed: isMobile ? 1 : 3,
    });
    const videoRellax = new Rellax(videoRef.current, {
      ...rellaxOptions,
      speed: -3,
    });
    const rellaxInstances = [introTextRellax, videoRellax];
    return () => {
      //reset for page changes
      rellaxInstances.forEach((instance) => {
        instance.destroy();
      });
    };
  });

  //body text toggle
  const toggleButtonRef = React.createRef();
  const bodyTextWrapper = React.createRef();
  const bodyTextInner = React.createRef();

  useToggleExpand(toggleButtonRef, bodyTextWrapper, bodyTextInner);

  return (
    <div className={styles.root}>
      <div className={styles.introWrapper}>
        <video className={styles.video} ref={videoRef} autoPlay muted loop>
          <source
            src={introBackgroundVideo ? introBackgroundVideo.asset.url : shieldVideo}
            type="video/mp4"
          />
        </video>
        <div className={styles.textWrapper} ref={introTextRef}>
          <div className={styles.text}>
            <h2 className={responsiveTitle2}>{title}</h2>
            <button className={cn(responsiveMicro, styles.toggleButton)} ref={toggleButtonRef}>
              {buttonCopy}
            </button>
            {body && (
              <div ref={bodyTextWrapper} className={styles.bodyTextWrapper}>
                <div ref={bodyTextInner} className={cn(bodyTextBlock, styles.bodyTextInner)}>
                  <PortableText blocks={body} />
                </div>
              </div>
            )}
            <div className={styles.logoAccent}>
              <img className={styles.shieldIcon} src={shieldIcon} alt="Contact Button" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
