import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { mediaMinSmall, mediaMaxSmall } from "../lib/mediaQueries";
import { cn } from "../lib/helpers";
import Lottie from "react-lottie-player";

import styles from "./services.module.css";

const Services = (props) => {
  const { showSplashScreen, splashPageEnabled, introLottieMobile, introLottieDesktop } = props;
  const isMobile = useMediaQuery(mediaMaxSmall);
  const isDesktop = useMediaQuery(mediaMinSmall);

  const [desktopAnimationData, setDesktopAnimationData] = useState(null);
  const [mobileAnimationData, setMobileAnimationData] = useState(null);

  useEffect(() => {
    const fetchMobileData = async () => {
      const response = await fetch(introLottieMobile.asset.url);
      let responseJson = await response.json();

      setMobileAnimationData(responseJson);
    };
    fetchMobileData();

    const fetchDesktopData = async () => {
      const response = await fetch(introLottieDesktop.asset.url);
      let responseJson = await response.json();

      setDesktopAnimationData(responseJson);
    };
    fetchDesktopData();
  }, []);

  const defaultOptions = {
    loop: true,
    play: !splashPageEnabled || !showSplashScreen,
  };

  return (
    <div className={styles.root}>
      {isMobile && mobileAnimationData && (
        <div className={cn(styles.animationWrapper, styles.mobileLottie)}>
          <Lottie {...defaultOptions} animationData={mobileAnimationData} />
        </div>
      )}
      {isDesktop && desktopAnimationData && (
        <div className={cn(styles.animationWrapper, styles.desktopLottie)}>
          <Lottie {...defaultOptions} animationData={desktopAnimationData} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { showSplashScreen: state.showSplashScreen };
};
export default connect(mapStateToProps)(Services);
